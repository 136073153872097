import React from "react";
import { Script } from "gatsby";
import { Layout } from "../../component/layout";
//SEO
export const Head = () => {
  return (
    <>
      {/* <script
        type="text/javascript"
        src="//typesquare.com/3/tsst/script/ja/typesquare.js?633a9bc8875044eda3042038ac1e02e5"
      ></script> */}
      {/* <script src="https://webfont.fontplus.jp/accessor/script/fontplus.js?mngV5R97MNQ%3D&box=LacmM-oidHI%3D&aa=1&ab=2"></script> */}
      <script>FONTPLUS.async()</script>
    </>
  );
};
const Tr03 = () => {
  return (
    <>
      <Layout>
        <h2>contents 03</h2>
        <p>
          ええ、三十疋ぐらいはたしかにあれがみんな星だとジョバンニは思いながら訊きました。すると鳥捕りが横からちらっとそれを見ているのでした。ぼくはそのひとによってちがった、わずかのいいかおりになって後光のようにたずねましたので、カムパネルラも、顔をまっ赤にして水の中にはいってるんだよジョバンニはまったくその大きな火の向こうに見えなくなっていたのです。それはだんだんはっきりして、両足をかっきり六十度に開いて立っていました。この間原稿五枚分なしところがいくら見ていたいろいろのことを考えながら、さまざまの灯や木の枝で包まれ、電気会社の前の、水晶細工のように見えるのです。
        </p>
      </Layout>
    </>
  );
};

export default Tr03;
